<template>
  <div class="relative">
    <ImageStrapiImage
      v-if="data?.background?.data"
      :data="data.background.data"
      class="absolute top-1/3 z-negative h-[800px] w-auto object-cover lg:top-[10%] lg:w-full" />
    <div
      v-if="data?.orientation === 'column'"
      class="lg:my-18 mx-auto my-12 flex max-w-screen-lg flex-col items-center justify-center px-5 md:my-12"
      :class="{ 'max-w-screen-xl': !customWidth }">
      <h2
        class="mb-6 max-w-screen-sm text-center text-3xl leading-tight text-gray-900 md:mb-12 md:text-4xl lg:mb-14 lg:text-5xl"
        :class="{ 'max-w-screen-md': !customWidth }">
        {{ data.title }}
      </h2>
      <h4 v-if="data.subtitle" class="mx-4 mb-2 text-center text-xl md:text-2xl">
        {{ data.subtitle }}
      </h4>
      <div class="grid grid-cols-12 items-start gap-y-8 md:gap-8 lg:gap-12">
        <SharedListItem v-for="item in data.listItems" :key="item.id" :data="item" class="col-span-12 md:col-span-6" />
      </div>
    </div>
    <div v-else class="mx-auto flex max-w-screen-xl flex-col items-center justify-center lg:my-5">
      <h2 class="mx-4 mb-6 text-center text-3xl text-gray-900 md:mb-12 md:text-4xl lg:text-5xl">
        {{ data?.title }}
      </h2>
      <div class="flex flex-wrap items-center justify-start">
        <SharedListItem
          v-for="item in data?.listItems"
          :key="item.id"
          :data="item"
          class="flex max-w-lg flex-[25%] flex-col justify-center p-12 text-center"
          :orientation="data?.orientation" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import type { ListItems } from "~/types/strapi/components/block/interfaces/ListItems";

defineProps<{
  data: ListItems;
}>();

const route = useRoute();
const slug = route.params.slug;

// Improvement could be: select a custom width in the BE ( full, 80%)
const slugs = ["besser-leben", "vital-check", "fuer-unternehmen"];
const customWidth = computed(() => Array.isArray(slug) && slugs.includes(slug[0]));
</script>

<style lang="scss" scoped></style>
